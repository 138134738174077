.dropdown {
    position: absolute;
    width: 150px;
    height: 250px;
    display: flex;
    background: white;
    flex-direction: column;
    max-height: 1%;
}
.material-icons{
    position: absolute;
}
.menu {
    list-style-type: none;
    margin-top: 3rem ;
    border: 1px solid grey;
    width: 20rem;
    background-color: white;
    padding: 3px;
    border-radius: 10px;
}
.menu > li {
    margin: 0;
    width: 100%;
    border-radius: 5px;
    font-size: 1.8rem;
    background-color: white;
}
.menu > li > button:hover {
    background-color: lightgray;
}
.menu-clear-filter{
    color: #145269;

}
.menu-title{
    padding: 3px;
    border-radius: 10px;
    font-size: 1rem;
}
.menu > li > button {
    height: 100%;
    width: 100%;
    text-align: left;
    background: none;
    color: inherit;
    border: none;
    padding: 5px;
    font: inherit;
    cursor: pointer;
}
